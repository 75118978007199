<template>
  <div>
    <div style="margin-top:30px;display:flex ;justify-content: center;">
      <div style="width:1500px">
        <el-carousel indicator-position="outside"
                     height="740px"
                     style="overflow-y: hidden;padding-bottom:30px;">
          <el-carousel-item v-for="(item,index) in datas"
                            :key="index"
                            style="">
            <ve-line :data="item.chartData"
                     :settings="item.chartSettings"
                     height="700px"
                     width="100%"
                     :judge-width="true"></ve-line>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      datas: [
        {
          chartSettings : {
        stack: { '用户': ['访问用户', '下单用户'] },
        area: true
      },
          chartData: {
            columns: ['日期', '访问用户', '下单用户', '下单率'],
            rows: [
              { '日期': '1/1', '访问用户': 1393, '下单用户': 1093, '下单率': 0.32 },
              { '日期': '1/2', '访问用户': 3530, '下单用户': 3230, '下单率': 0.26 },
              { '日期': '1/3', '访问用户': 2923, '下单用户': 2623, '下单率': 0.76 },
              { '日期': '1/4', '访问用户': 1723, '下单用户': 1423, '下单率': 0.49 },
              { '日期': '1/5', '访问用户': 3792, '下单用户': 3492, '下单率': 0.323 },
              { '日期': '1/6', '访问用户': 4593, '下单用户': 4293, '下单率': 0.78 },
              { '日期': '1/7', '访问用户': 3530, '下单用户': 3230, '下单率': 0.26 },
              { '日期': '1/8', '访问用户': 2923, '下单用户': 2623, '下单率': 0.76 },
              { '日期': '1/9', '访问用户': 1723, '下单用户': 1423, '下单率': 0.49 },
              { '日期': '1/10', '访问用户': 3792, '下单用户': 3492, '下单率': 0.323 },
              { '日期': '1/11', '访问用户': 4593, '下单用户': 4293, '下单率': 0.78 },
              { '日期': '1/12', '访问用户': 3530, '下单用户': 3230, '下单率': 0.26 },
              { '日期': '1/13', '访问用户': 2923, '下单用户': 2623, '下单率': 0.76 },
              { '日期': '1/14', '访问用户': 1723, '下单用户': 1423, '下单率': 0.49 },
              { '日期': '1/15', '访问用户': 3792, '下单用户': 3492, '下单率': 0.323 },
              { '日期': '1/16', '访问用户': 4593, '下单用户': 4293, '下单率': 0.78 },
              { '日期': '1/17', '访问用户': 3530, '下单用户': 3230, '下单率': 0.26 },
              { '日期': '1/18', '访问用户': 2923, '下单用户': 2623, '下单率': 0.76 },
              { '日期': '1/19', '访问用户': 1723, '下单用户': 1423, '下单率': 0.49 },
              { '日期': '1/21', '访问用户': 1393, '下单用户': 1093, '下单率': 0.32 },
              { '日期': '1/22', '访问用户': 3530, '下单用户': 3230, '下单率': 0.26 },
              { '日期': '1/23', '访问用户': 2923, '下单用户': 2623, '下单率': 0.76 },
              { '日期': '1/24', '访问用户': 1723, '下单用户': 1423, '下单率': 0.49 },
              { '日期': '1/25', '访问用户': 3792, '下单用户': 3492, '下单率': 0.323 },
              { '日期': '1/26', '访问用户': 4593, '下单用户': 4293, '下单率': 0.78 },
              { '日期': '1/27', '访问用户': 3530, '下单用户': 3230, '下单率': 0.26 },
              { '日期': '1/28', '访问用户': 2923, '下单用户': 2623, '下单率': 0.76 },
              { '日期': '1/29', '访问用户': 1723, '下单用户': 1423, '下单率': 0.49 },
            ]
          }
        }]
    }

  }
}

</script>

<style >
.el-carousel__item div {
  color: #475669;
  opacity: 0.95;
}
.el-carousel__item {
}

.el-carousel__item:nth-child(2n) {
  /* background-color: #ebeef5; */
}

.el-carousel__item:nth-child(2n + 1) {
  /* background-color: #f2f6fc; */
}
</style>